import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';
export enum SecurityFormStatus {
  Success = 'edit_password_success',
  Fail = 'edit_password_fail'
}
export const trackSecurityForm = (status: SecurityFormStatus) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.User),
    event_action: `${status}`,
    event_label: `${
      status === SecurityFormStatus.Success ? 'edit_password_success' : 'edit_password_fail_empty_newpassword'
    }`
  });
};

import { FunctionComponent, useState } from 'react';
import { withTrans } from '@shares/locales/hoc';
import { withFormik, FormikProps } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { object, string, InferType } from 'yup';
import { authSetPassword } from '@ui/components/context/auth';
import { AlertType } from '@shares/alert-box';
import { UserProfileSecurityProps } from './';
import { trackSecurityForm, SecurityFormStatus } from './tracking';
import './style.scss';

const SetPassword: FunctionComponent<UserProfileSecurityProps & TranslationProps & FormikProps<SecurityFormValues>> = (
  props
) => {
  const { errors, values, handleBlur, touched } = props;
  const [showNewPassword, setNewShowPassword] = useState<boolean>(false);

  return (
    <div className="security p-3 p-lg-0">
      <h2>ตั้งรหัสผ่าน</h2>
      <Form onSubmit={(e: any) => props.handleSubmit(e)} className="security-form">
        <Row>
          <Col lg={6}>
            <Form.Group className="position-relative">
              <Form.Label>รหัสผ่านใหม่</Form.Label>
              <Form.Control
                id="account-new-password-input"
                type={showNewPassword ? 'text' : 'password'}
                placeholder="ตั้งรหัสผ่านใหม่"
                name="new_password"
                value={values.new_password}
                onChange={(e) => {
                  props.setFieldTouched('new_password', true);
                  props.handleChange(e);
                }}
                isInvalid={errors.new_password !== undefined && (touched.new_password as boolean)}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  const code = e.which ? e.which : e.keyCode;
                  if (code == 32) {
                    e.preventDefault();
                  }
                }}
              />
              <i
                className={showNewPassword ? 'icon-eye' : 'icon-eye-off'}
                onClick={() => setNewShowPassword(!showNewPassword)}
              />
              <Form.Control.Feedback type="invalid">{errors.new_password}</Form.Control.Feedback>
              <Form.Text className={`text-muted ${errors.new_password && touched ? 'd-none' : ''}`}>
                กรุณาตั้งรหัสผ่านที่มีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Button
          id="account-password-submit"
          type="submit"
          block
          variant={props.isError ? 'danger' : 'primary'}
          disabled={props.isSubmitting || props.isError ? true : false}
          className={props.isSubmitting ? 'submiting' : ''}
        >
          {props.isSubmitting ? (
            <img src="/assets/shares/loading.svg" style={{ width: 38 }} />
          ) : props.isError ? (
            'โปรดลองใหม่อีกครั้ง'
          ) : (
            'บันทึก'
          )}
        </Button>
      </Form>
    </div>
  );
};

const textError = 'รหัสผ่านต้องมีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข';
const schema = () => {
  return object()
    .shape({
      new_password: string()
        .required('กรุณากรอกรหัสผ่านใหม่ของคุณ')
        .min(8, textError)
        .matches(/^(?:(?=.*[a-z])|(?=.*\d)).{8,}$/, textError)
        .defined()
    })
    .defined();
};
type SecurityFormValues = InferType<ReturnType<typeof schema>>;
const SetPasswordForm = withFormik<UserProfileSecurityProps, SecurityFormValues>({
  mapPropsToValues: () => ({
    new_password: ''
  }),
  validationSchema: schema(),
  handleSubmit: (values, formikBag) => {
    authSetPassword(values.new_password)
      .then(() => {
        trackSecurityForm(SecurityFormStatus.Success);
        formikBag.setSubmitting(false);
        formikBag.resetForm({});
        formikBag.props.toggleAlertBox(AlertType.Success);
      })
      .catch((e: any) => {
        console.log(e.data);
        formikBag.setSubmitting(false);
        trackSecurityForm(SecurityFormStatus.Fail);
        if (e.status === 400) {
          return formikBag.props.toggleAlertBox(AlertType.Error, 'รหัสผ่านไม่ถูกต้อง โปรดลองใหม่อีกครั้ง');
        }
        formikBag.props.toggleAlertBox(AlertType.Error);
      });
  }
})(withTrans('auth.security')(SetPassword));

export default SetPasswordForm;
